import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.ThumbnailPlaceholderQuery['file'] => {
  const result = useStaticQuery<GatsbyTypes.ThumbnailPlaceholderQuery>(graphql`
    query ThumbnailPlaceholder {
      file(name: {eq: "tile-placeholder-900x900"}) {
        childImageSharp {
          gatsbyImageData(
            width: 450,
            height: 300,
          )
        }
      }
    }
  `);
  return result.file;
};
