import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base } from './Base';

export class Faq extends Base {
  constructor(
    public question: string,
    public answerHTML: string,
    public icon?: IGatsbyImageData,
  ) {
    super();
  }
}
