import React from 'react';
import { FAQPage } from 'schema-dts';
import SchemaBase from 'lib/components/schemas/SchemaBase';


interface Props {
  faqs: {
    question: string,
    answer: string,
  }[],
}


const FaqSchema:React.FC<Props> = ({ faqs }) => {
  const schema: FAQPage = {
    '@type': 'FAQPage',
    mainEntity: faqs.map(({ question, answer }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    })),
  };
  return <SchemaBase schema={schema} />;
};
export default FaqSchema;
