import { Link } from 'gatsby';
import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import Typography from 'components/Typography';
import Container from 'components/Container';
import HoverEffect from 'components/HoverEffect';
import Dropdown from 'components/Dropdown';
import useFromBreakpoint from 'src/hooks/useFromBreakpoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import Spacer from 'components/Spacer';


interface NavItem {
  url: string,
  label: string,
  active?: boolean,
}

export interface Props {
  items: NavItem[],
  borderless?: boolean,
  promptText?: string,
}


const SubNavMenu:React.FC<Props> = ({
  items,
  borderless = false,
  promptText = 'Explore our services',
}) => {
  const theme = React.useContext(ThemeContext);
  const isMobile = !useFromBreakpoint('phoneWide');
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);

  const ConditionalDropdown = isMobile ? Dropdown : React.Fragment;
  const conditionalDropdownProps = isMobile ? { isActive: isDropdownActive } : {};

  const handleDropdownTriggerClick = () => setIsDropdownActive((oldState) => !oldState);

  return (
    <SubNavMenuOuter
      className={borderless ? 'borderless' : 'border'}
    >
      <Container yPadding={5}>
        <DropdownTrigger>
          <ButtonStyled
            onClick={handleDropdownTriggerClick}
            type="button"
            className={isDropdownActive ? 'active' : 'inactive'}
          >
            <IconWrap>
              <FontAwesomeIcon icon={isDropdownActive ? faTimes : faBars} />
            </IconWrap>
            <TextWrap>
              <Typography color={theme.palette.interactive} variant="small">{promptText}</Typography>
            </TextWrap>
          </ButtonStyled>
        </DropdownTrigger>
        <ConditionalDropdown {...conditionalDropdownProps}>
          <LinksContainer>
            {items.map(({ url, label, active = false }) => (
              <LinkWrap key={url + label}>
                <LinkStyled
                  to={active ? undefined : url}
                  as={(active ? 'div' : Link) as any} /* eslint-disable-line */
                  className={active ? 'active' : 'inactive'}
                >
                  <HoverEffect
                    color={theme.palette.interactive}
                    underline={false}
                    underlineFade
                    alwaysOn={active}
                  >
                    <Typography variant="body" bold>{label}</Typography>
                  </HoverEffect>
                </LinkStyled>
              </LinkWrap>
            ))}
          </LinksContainer>
        </ConditionalDropdown>
      </Container>
    </SubNavMenuOuter>
  );
};
export default SubNavMenu;

const SubNavMenuOuter = styled.div`
  &.border {
    border-top: 1px solid ${(p) => p.theme.palette.secondary};
  }
`;
const DropdownTrigger = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px 0;
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    display: none;
  }
`;
const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
`;
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  background-color: ${(p) => p.theme.palette.interactive};
  color: ${(p) => p.theme.palette.primary};
  border-radius: 50%;
  transition: transform 0.1s;
  ${ButtonStyled}.active & {
    transform: rotate(180deg);
  }
`;
const TextWrap = styled.div`
  margin-left: 10px;
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.2s, opacity 0.2s;
  ${ButtonStyled}.active & {
    transform: translateX(-30px);
    opacity: 0;
  }
`;
const LinksContainer = styled.div`
  font-size: 1.2rem;
  padding: 5px 0;
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
  }
`;
const LinkWrap = styled.div`
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    padding: 5px;
  }
`;
const LinkStyled = styled(Link)`
  position: relative;
  display: block;
  padding: 0.5em;
  text-decoration: none;
  &.active {
    cursor: default;
  }
`;
