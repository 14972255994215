import { capitalizeFirstLetter } from 'src/helpers';
import { Base } from './Base';

export class Author extends Base {
  constructor(
    public avatar: {
      width: number,
      height: number,
      url: string,
    },
    public biography?: string,
    private _firstName?: string,
    private _lastName?: string,
    private _name?: string,
  ) { super(); }

  get name(): string {
    const {
      _firstName: firstName,
      _lastName: lastName,
      _name: name,
    } = this;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    if (firstName) return firstName;
    if (lastName) return lastName;
    if (name) return capitalizeFirstLetter(name);
    return 'Blueclaw';
  }
}
