import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import CtaButton from 'src/components/CtaButton';
import Spacer from 'src/components/Spacer';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import SubNavMenu, { Props as SubNavMenuProps } from 'src/components/SubNavMenu';
import { resolveUrl } from 'src/helpers';
import PostQuery_Post from 'src/transformers/PostQuery_Post';
import GenericGridBlock from 'src/components/GenericGridBlock';
import GeneralTile from 'src/components/tiles/GeneralTile';
import CtaBlock from 'src/components/CtaBlock';
import CaseStudyQuery_CaseStudy from 'src/transformers/CaseStudyQuery_CaseStudy';
import TestimonialBlock from 'src/components/TestimonialBlock';
import TestimonialQuery_Testimonial from 'src/transformers/TestimonialQuery_Testimonial';
import faqTransformer from 'src/transformers/FaqQuery_Faq';
import FaqAccordion from 'src/components/accordions/FaqAccordion';
import Seo from 'src/lib/components/Seo';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';
import isNotNull from 'src/types/guards/isNotNull';

interface Props {
  data: GatsbyTypes.SubServiceAndParentQuery,
  // pageContext: { subServiceId: string },
}

export const query = graphql`
  query SubServiceAndParent(
    $subServiceId: String, 
    $parentServiceId: String, 
    $parentServiceSlug: String
  ) {
    subService: wpSubService(id: { eq: $subServiceId }) {
      ...SubServiceBasic
      acf {
        copyBlock1
        linkedFaqs {
          ... on WpFaq {
            ...FaqFragment
          }
        }
        linkedTestimonial {
          ... on WpTestimonial {
            ...TestimonialWithThumbnail
          }
        }
      }
    }
    parentService: wpService(id: { eq: $parentServiceId } ) {
      ...ServiceBasic
      acf {
        linkedSubServices {
          ... on WpSubService {
            ...SubServiceBasic
          }
        }
        linkedCaseStudies {
          ... on WpCaseStudy {
            ...CaseStudyWithThumbnail
          }
        }
      }
    }
    relatedPosts: allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: $parentServiceSlug}}}}}
      sort: {fields: date, order: DESC}
      limit: 3
    ) {
      nodes {
        ...PostWithThumbnail
      }
    }
  }
`;


const ServicePage: React.FC<Props> = ({ data }) => {
  const { subService, parentService } = data;
  const relatedPostsRaw = data.relatedPosts.nodes;

  const pageDataSubService = BasicQuery_SitePage(subService || {});
  const pageDataParentService = BasicQuery_SitePage(parentService || {});

  const relatedPosts = Array.from(relatedPostsRaw, PostQuery_Post).filter(isNotNull);

  const caseStudiesRaw = parentService?.acf?.linkedCaseStudies || [];
  const caseStudies = Array.from(caseStudiesRaw, CaseStudyQuery_CaseStudy).filter(isNotNull);

  const testimonialsArray = subService?.acf?.linkedTestimonial || [];
  const [testimonialRaw] = testimonialsArray;
  const testimonial = TestimonialQuery_Testimonial(testimonialRaw);

  const faqLists = subService?.acf?.linkedFaqs;
  const firstFaqList = faqLists && faqLists?.length > 0 ? faqLists[0] : undefined;
  const faqs = faqTransformer(firstFaqList);

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Services', url: resolveUrl.service('') },
    {
      label: pageDataParentService.navTitle,
      url: resolveUrl.service(parentService?.slug),
    },
    { label: pageDataSubService.navTitle },
  ];

  const subNavItems: SubNavMenuProps['items'] = parentService?.acf?.linkedSubServices?.map((siblingSubService) => {
    const pageDataSiblingService = BasicQuery_SitePage(siblingSubService || {});
    return {
      label: pageDataSiblingService.navTitle,
      url: resolveUrl.subService(pageDataSiblingService.slug, pageDataParentService.slug),
      active: pageDataSiblingService.slug === pageDataSubService.slug,
    };
  }) || [];

  return (
    <>
      <Seo
        title={pageDataSubService.h1}
        // titlePath={['Services', parentService?.title]}
        description={pageDataSubService.metaDescription}
      />
      <PageHeader
        // category={parentService?.title}
        category="Services"
        h1={pageDataSubService.h1}
        backgroundText={pageDataSubService.contentHtml}
      />
      {subNavItems.length > 0 && (
        <Block darkBg type="noPadding">
          <SubNavMenu items={subNavItems} />
        </Block>
      )}

      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />
        <Block>
          <ContentParser>{pageDataSubService.contentHtml}</ContentParser>
          <Spacer mb={4} />
          <CtaButton type="primary" />
        </Block>
      </Container>

      {caseStudies?.length > 0 && (
        <Block>
          <Container yPadding={0}>
            <GenericGridBlock title={`${parentService?.title || ''} case studies`} smallTitle>
              {caseStudies.slice(0, 3).map((caseStudy) => (
                <GeneralTile
                  key={caseStudy.title}
                  title={caseStudy.title}
                  image={caseStudy.image}
                  href={resolveUrl.caseStudy(caseStudy.slug)}
                />
              ))}
            </GenericGridBlock>
          </Container>
        </Block>
      )}

      {testimonial && (
        <Block darkBg type="noPadding">
          <Container>
            <TestimonialBlock
              humanName={testimonial.name}
              humanTitle={testimonial.jobTitle}
              quoteHTML={testimonial.quoteCopyHTML}
            />
          </Container>
        </Block>
      )}

      {subService?.acf?.copyBlock1 && (
        <Container>
          <Block>
            <ContentParser>{subService.acf.copyBlock1}</ContentParser>
          </Block>
        </Container>
      )}


      {faqs && faqs.length > 0 && (
        <Block contain readableWidth>
          <FaqAccordion faqs={faqs} />
        </Block>
      )}

      {relatedPosts?.length > 0 && (
        <Block darkBg>
          <Container yPadding={0}>
            <GenericGridBlock title={`Latest ${pageDataParentService.navTitle} insight`} smallTitle>
              {relatedPosts.slice(0, 3).map((post) => (
                <GeneralTile
                  key={post.title}
                  title={post.title}
                  image={post.featuredImage}
                  href={resolveUrl.post(post.slug)}
                />
              ))}
            </GenericGridBlock>
          </Container>
        </Block>
      )}

      <CtaBlock />
    </>
  );
};

export default ServicePage;
