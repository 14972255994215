import React from 'react';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import SafeHtml from 'src/lib/components/SafeHtml';

interface Props {
  title?: string,
  humanName: string,
  humanTitle?: string,
  quoteHTML: string,
  image?: IGatsbyImageData,
}

const TestimonialBlock:React.FC<Props> = ({
  title = 'Testimonal',
  humanName,
  humanTitle,
  quoteHTML,
  image,
}) => {
  return (
    <TestimonialBlockOuter>
      {title && (
        <>
          <Typography variant="h3" as="p">{title}</Typography>
          <Spacer mb={4} />
        </>
      )}
      <FlexSpacer mx={-1}>
        {image && (
          <Spacer px={1}>
            <GatsbyImage image={image} alt={`${humanName} testimonial image`} />
          </Spacer>
        )}
        <Spacer px={1}>
          <QuoteWrap>
            <SafeHtml
              html={quoteHTML}
              as={(props) => (
                <Typography variant="bodyPullout" as="div" bold italic maxWidth="32em" {...props} />
              )}
            />
          </QuoteWrap>
          <Spacer mb={2} />
          <Typography>{humanName}</Typography>
          <Spacer mb={0.5} />
          {humanTitle && (
            <Typography variant="small" italic>{humanTitle}</Typography>
          )}
        </Spacer>
      </FlexSpacer>
    </TestimonialBlockOuter>
  );
};
export default TestimonialBlock;

const TestimonialBlockOuter = styled.div``;
const FlexSpacer = styled(Spacer)`
  display: flex;
  align-items: center;
`;
const QuoteWrap = styled.div`
  display: flex;
  &::before {
    content: '“';
    font-size: 3rem;
    ${({ theme }) => theme.breakpoints.up('phoneWide')} {
      font-size: 7rem;
    }
  }
`;
