import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Post } from 'models/Post';
import { resolveUrl } from 'helpers';
import { Author } from 'src/models/Author';
import useThumbnailPlaceholder from 'src/hooks/queries/fileSystemAssets/useThumbnailPlaceholder';

interface RequiredInput {
  [key:string]: any,
  title?: GatsbyTypes.WpPost['title'],
  slug?: GatsbyTypes.WpPost['slug'],
  content?: GatsbyTypes.WpPost['content'],
  date?: GatsbyTypes.WpPost['date'],
  status?: GatsbyTypes.WpPost['status'],
  readonly categories?: GatsbyTypes.Maybe<{
    readonly nodes: GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<Pick<GatsbyTypes.WpCategory, 'slug' | 'id' | 'name'>>[]>;
  }>
  author?: {
    node?: {
      name?: string,
      firstName?: string,
      lastName?: string,
      description?: string,
      avatar?: {
        url?: string,
        width?: number,
        height?: number,
      }
    }
  },
  featuredImage?: {
    node?: {
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData,
        }
      }
    }
  }
  acf?: {
    readingTime?: number,
  }
}


export default (
  queryData?: RequiredInput,
): Post | null => {
  if (!queryData) return null;

  const thumbnailPlaceholder = useThumbnailPlaceholder();

  return new Post(
    queryData.title || '',
    queryData.slug || '',
    queryData.content || '',
    queryData.date || '',
    queryData.status || '',
    new Author(
      {
        width: queryData.author?.node?.avatar?.width || 0,
        height: queryData.author?.node?.avatar?.height || 0,
        url: queryData.author?.node?.avatar?.url || '',
      },
      queryData.author?.node?.description,
      queryData.author?.node?.firstName,
      queryData.author?.node?.lastName,
      queryData.author?.node?.name,
    ),
    (
      queryData.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
      || thumbnailPlaceholder?.childImageSharp?.gatsbyImageData
    ),
    queryData.categories?.nodes?.map((node) => ({
      label: node?.name || '',
      href: resolveUrl.category(node?.slug || '', resolveUrl.post('')),
    })) || [],
    queryData?.acf?.readingTime,
  );
};
