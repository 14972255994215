import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Author } from './Author';
import { Base } from './Base';

export class Post extends Base {
  constructor(
    public title: string,
    public slug: string,
    public content: string,
    public date: string,
    public status: string,
    public author: Author,
    public featuredImage?: IGatsbyImageData,
    public taxonomyLinks?: {
      href: string,
      label: string,
    }[],
    public readingTime?: number,
  ) {
    super();
  }
}
