import React from 'react';
import styled from 'styled-components';
import Accordion from 'components/Accordion';
import FaqSchema from 'components/schemas/FaqSchema';
import { Faq } from 'models/Faq';
import { AccordionItem } from 'components/Accordion/types';

interface Props {
  faqs: Faq[],
  title?: string,
}

const FaqAccordion:React.FC<Props> = ({
  faqs,
  title = 'FAQs',
}) => {
  const schemaFaqs = faqs.map(({ question, answerHTML }) => ({
    question,
    answer: answerHTML,
  }));
  const slideFaqs = faqs.map<AccordionItem>((faq) => ({
    id: faq._id,
    title: faq.question,
    contentHTML: faq.answerHTML,
    icon: faq.icon,
  }));

  return (
    <FaqAccordionOuter>
      <FaqSchema faqs={schemaFaqs} />
      <Accordion items={slideFaqs} title={title} />
    </FaqAccordionOuter>
  );
};
export default FaqAccordion;

const FaqAccordionOuter = styled.div``;
