import React from 'react';
// import styled from 'styled-components';
import Container from 'src/components/Container';
import Block, { Props as BlockProps } from 'src/components/Block';
import CtaButton from 'components/CtaButton';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';
import useSiteText from 'src/hooks/queries/useSiteText';

interface Props {
  title?: string,
  primaryText?: string,
  secondaryText?: string,
  buttonTxt?: string,
  buttonAction?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  blockConfig?: BlockProps,
}

const CtaBlock:React.FC<Props> = ({
  title = 'Contact.',
  primaryText: primaryTextProp,
  secondaryText: secondaryTextProp,
  buttonTxt = 'Talk to our experts',
  buttonAction,
  blockConfig = {
    highlightBg: true,
  },
}) => {
  const siteText = useSiteText();
  const primaryText = primaryTextProp || siteText.ctaBlockPrimaryText;
  const secondaryText = secondaryTextProp || siteText.ctaBlockSecondaryText;
  return (
    <Block readableWidth contain {...blockConfig} title={title}>
      <Container xPadding={0} yPadding={0} shift>
        {primaryText && (
          <Spacer mb={4} mt={1}>
            <Typography variant="pageSubTitle">{primaryText}</Typography>
          </Spacer>
        )}
        {secondaryText && (
          <Spacer mb={5}>
            <Typography>{secondaryText}</Typography>
          </Spacer>
        )}
        <CtaButton onClick={buttonAction} type="primary">
          {buttonTxt}
        </CtaButton>
      </Container>
    </Block>
  );
};
export default CtaBlock;
