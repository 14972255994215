import { Faq } from 'models/Faq';


export default (
  queryData?: GatsbyTypes.FaqFragmentFragment,
): Faq[] | null => {
  const questions = queryData?.acf?.faqs;
  if (!questions?.length) return null;

  const faqs: Faq[] = [];
  for (let i = 0; i < questions.length; i += 1) {
    const question = questions[i];
    if (typeof question?.question === 'undefined') throw new Error('missing FAQ question text');
    if (typeof question?.answer === 'undefined') throw new Error('missing FAQ answer text');
    faqs.push(new Faq(
      question.question,
      question.answer,
      question?.icon?.localFile?.childImageSharp?.gatsbyImageData,
    ));
  }

  return faqs;
};
